.IntelligentSecurity * {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

/* 中等大小 */
.echartsModelS {
    width: 100%;
    height: 100%;
}
.echartsModelS>.echartsModelS-title {
    height: 4.1667vh;
    line-height: 4.1667vh;
    width: 100%;
    background-image: url(../../static/img/modelTitleS.9fd068a3.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-size: 1vw;
    font-family: 'PingFangSt';
    color: #D9EAF5;
    /* font-style: italic; */
    font-weight: bold;
    padding-left: 4.1667vh;
}
.echartsModelS>.echartsModelS-chart {
    width: 100%;
    height: calc(100% - 4.1667vh);
    background-image: url(../../static/img/chartBackgroundS.0cd34fc0.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.circleChart,
.circleChartL {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.circleChart>div:first-child,
.circleChartL>div:first-child {
    width: 40%;
    height: 100%;
}
.circleChart>div:last-child,
.circleChartL>div:last-child {
    width: 60%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.circleChart>div:last-child>div,
.circleChartL>div:last-child>div {
    width: 50%;
}

/* 第一行标题 */
.circleChart>div:last-child>div:nth-child(1),
.circleChart>div:last-child>div:nth-child(2) {
    font-size: 0.729vw;
    color: #e3f3ff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    margin-bottom: 25px;
}
.circleChart>div:last-child>div:nth-child(1)::before,
.circleChart>div:last-child>div:nth-child(2)::before {
    content: '';
    display: inline-block;
    width: 0.104vw;
    height: 0.729vw;
    background: -webkit-gradient(linear, left top, left bottom, from(#0AF), to(#00FFE0));
    background: linear-gradient(180deg, #0AF 0%, #00FFE0 100%);
    margin-right: 10px;
    margin-bottom: 3px;
}

/* 第二行数字 */
.circleChart>div:last-child>div:nth-child(3),
.circleChart>div:last-child>div:nth-child(4) {
    font-size: 1.146vw;
    color: #00aaff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}

/* 4300 1290 */
/* 第一行标题 */
.circleChartL>div:last-child>div:nth-child(1),
.circleChartL>div:last-child>div:nth-child(2) {
    font-size: 0.326vw;
    color: #e3f3ff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    margin-bottom: 0.581vw;
}
.circleChartL>div:last-child>div:nth-child(1)::before,
.circleChartL>div:last-child>div:nth-child(2)::before {
    content: '';
    display: inline-block;
    width: 0.047vw;
    height: 0.465vw;
    background: -webkit-gradient(linear, left top, left bottom, from(#0AF), to(#00FFE0));
    background: linear-gradient(180deg, #0AF 0%, #00FFE0 100%);
    margin-right: 0.233vw;
    margin-bottom: 0.07vw;
}

/* 第二行数字 */
.circleChartL>div:last-child>div:nth-child(3),
.circleChartL>div:last-child>div:nth-child(4) {
    font-size: 0.605vw;
    color: #00aaff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}

/* 列表 */
.listBox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    color: #E3F3FF;
    padding: 0.9259vh;
}
.listBox>.listBox-item {
    width: 100%;
    height: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.listBox span {
    line-height: 2.3148vh;
    display: inline-block;
    height: 2.3148vh;
    font-size: 1.2963vh;
}
.listBox>.listBox-item>div {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

/* 去除标题的外边距 */
/* .listBox-item>div>span {
    padding: 0 0.9259vh;
    margin: 0 0.9259vh 0.9259vh 0;
} */
.listBox-title {
    height: calc(33% - 0.9259vh);
    vertical-align: text-top;
    padding-left: 0.9259vh;
}
.itemS {
    background-image: url(../../static/img/liBackgroundS.81b57aef.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0 0.463vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0 0.9259vh 0.9259vh 0.9259vh;
    height: calc(33% - 00.9259vh);
}
.itemL {
    background-image: url(../../static/img/liBackgroundL.c9ac277a.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0 0.463vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0 0.9259vh 0.9259vh 0.9259vh;
    height: calc(33% - 00.9259vh);
}
.listBox-itemL {
    padding: 0.465vw;
    color: #e3f3ff;
}
.itemSL {
    background-image: url(../../static/img/liBackgroundS.81b57aef.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0.116vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0 0.116vw 0.116vw 0.116vw;
    font-size: 0.372vw;
}
.itemLL {
    background-image: url(../../static/img/liBackgroundL.c9ac277a.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0.116vw;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0 0.116vw 0.116vw 0.116vw;
    font-size: 0.372vw;
}

/* 世界地图地球切换 */
.wordMapBox {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 20.833vw !important;
    height: 2.344vw !important;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 999;
}
.activeWordMap {
    width: 8.333vw;
    height: 2.344vw;
    background-image: url(../../static/img/activeWordMap.da657d12.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #fff;
    text-shadow: 0.156vw 0.156vw 0.156vw #11141638;
    font-size: 1.146vw;
    font-weight: bold;
    font-style: italic;
    text-align: center;
    line-height: 2.344vw;
    cursor: pointer;
}
.wordMap {
    width: 8.333vw;
    height: 2.344vw;
    background-image: url(../../static/img/wordMap.c4dee935.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: #fff;
    text-shadow: 0.156vw 0.156vw 0.156vw #11141638;
    font-size: 1.146vw;
    font-weight: bold;
    font-style: italic;
    text-align: center;
    line-height: 2.344vw;
    cursor: pointer;
}

/* 4300 1290 */
.modelBody-title-L {
    width: 100%;
    height: 1.047vw;
    line-height: 1.047vw;
    background-image: url(../../static/img/modelTitleL.98a5dbe8.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-size: 0.558vw;
    font-family: 'PingFangSt';
    color: #fff;
    font-style: italic;
    font-weight: bold;
    padding-left: 1.047vw;
}
.modelBody-body-L {
    width: 100%;
    height: calc(100% - 1.047vw);
    background-image: url(../../static/img/chartBackgroundL.b23856bc.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.modelBody-title-S {
    width: 100%;
    height: 1.047vw;
    line-height: 1.047vw;
    background-image: url(../../static/img/modelTitleS.9fd068a3.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-size: 0.558vw;
    font-family: 'PingFangSt';
    color: #fff;
    font-style: italic;
    font-weight: bold;
    padding-left: 1.047vw;
}
.modelBody-body-S {
    width: 100%;
    height: calc(100% - 1.047vw);
    background-image: url(../../static/img/chartBackgroundS.0cd34fc0.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}